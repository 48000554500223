.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

.logo_sm {
  width:20%
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }

  .rounded-circle {
    width:40% !important
  }

  Table {
    width:100% !important
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.client_logo {
  height:70px
}

.client_card {
  padding:10px;
  background-color: #f0f7ff;
  width: 100%
}

.header_card {
  background-color: #ffffff;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.card-content {
  padding: 15px;
}

td {
  border: 1px solid #cccccc ;
  border-top: none !important;
}

.table_data {
  display: flex;
  flex-wrap: wrap;
}

.hero, p.lead{
  margin-bottom: 20px !important
}

.row {
  justify-content: space-between;
}

.textArea {
  width:100%;
  height:350px
}

.newUpdate {
  background-color: #ece2ff;
  padding: 15px;
}

.imgComms {
  max-width: 50%;
}

.commentBox {
  padding: 5px 15px;
  background-color: #ece4ff;
  border-top: 7px solid #a1c5ff;
  margin-bottom: 15px;
}

.previewMod {
  display: flex;
  flex-direction: column
}

.userBar {
  display: flex;
    align-items: center;
    background-color: #ededed;
    padding: 15px;
}

.imgProf {
  margin-right: 15px;
}

.content_box {
  margin-top:15px
}

.dateTime {
  font-size: 13px;
}

.rta {
position: relative;
font-size: 18px;
width: 100%;
height: 100%;
}

.rta__autocomplete {
  position: absolute;
  display: block;
  -webkit-box-shadow: 7px 7px 5px -4px rgba(0,0,0,0.27);
-moz-box-shadow: 7px 7px 5px -4px rgba(0,0,0,0.27);
box-shadow: 7px 7px 5px -4px rgba(0,0,0,0.27);
background-color:#ffffff
}

.rta__list {
  margin: 0;
  list-style-type: none;
  padding: 0px 10px;
}

.hero {
  max-width: 800px !important;
}

@media (min-width: 1200px) {
.container {
    /* max-width: 1140px; */
    width: 100%;
    max-width: 1520px;
}
}

